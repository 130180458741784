@import "http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext";
body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide-in-top-enter {
  transform: translateY(-100%);
}

.slide-in-top-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-top-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-top-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}
