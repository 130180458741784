@import url(http://fonts.googleapis.com/css?family=Lato&subset=latin,latin-ext);
body {
  margin: 0;
  font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.slide-in-top-enter {
  transform: translateY(-100%);
}

.slide-in-top-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.slide-in-top-exit {
  transform: translateY(0%);
  opacity: 1;
}

.slide-in-top-exit-active {
  transform: translateY(-100%);
  opacity: 0;
  transition: all 200ms;
}

.modal {
  z-index: 100;
  position: fixed;
  top: 22vh;
  left: 10%;
  width: 80%;
  background: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  border-radius: 8px;
}

.modal__header {
  width: 100%;
  padding: 1rem 0px;
  background: #3f7f66;
  color: white;
}

.modal__header h2 {
  margin: 1.5rem;
}

.modal__content {
  padding: 1.5rem 1.5rem;
}

.modal__footer {
  padding: 1.5rem 1.5rem;
}

@media (min-width: 768px) {
  .modal {
    left: calc(50% - 20rem);
    width: 40rem;
  }
}

.modal-enter {
  transform: translateY(-10rem);
  opacity: 0;
}

.modal-enter-active {
  transform: translateY(0);
  opacity: 1;
  transition: all 200ms;
}

.modal-exit {
  transform: translateY(0);
  opacity: 1;
}

.modal-exit-active {
  transform: translateY(-10rem);
  opacity: 0;
  transition: all 200ms;
}

